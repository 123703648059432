/* React IMPORTS */ 

/* STYLE IMPORTS */
import style from "./contact.module.css"

/* COMPONENT IMPORTS */ 

function Contact() {
    return (
        <>
            <h1>Contact</h1>
        </>
    )
}

export default Contact